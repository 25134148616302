import React from "react";
import "../index.css";
import DesktopIcon from "./desktopicon";
import Notepad from "../Icons/Notepad.ico";
import myComputerIcon from "../Icons/computer.png";

function DesktopIcons({ handleClick }) {
  return (
    <div className="grid-container">
      {content.map((icon, pos) => (
        <DesktopIcon
          handleClick={() => handleClick(icon.windowName, true)}
          src={icon.src}
          alt={icon.alt}
          text={icon.text}
          key={pos}
        />
      ))}
    </div>
  );
}
let content = [
  {
    src: myComputerIcon,
    alt: "MyComputer Icon",
    text: "Work",
    windowName: "myComputer",
  },
  {
    src: Notepad,
    alt: "Codevex",
    text: "CodeVex",
    windowName: "aboutMe",
  },
];
export default DesktopIcons;

import React from "react";
import {
  AppBar,
  Toolbar,
  // TextField,
  Button,
  List,
  ListItem,
  Divider,
} from "react95";
import windowsLogo from "../Icons/codevex.png";
import computercard from "../Icons/ComputerCard.ico";

const WindowsStartBar = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <AppBar style={{ position: "static" }}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        <div style={{ position: "relative", display: "inline-block" }}>
          <Button
            onClick={() => setOpen(!open)}
            active={open}
            style={{ fontWeight: "bold" }}
          >
            <img
              src={windowsLogo}
              alt="react95 logo"
              style={{ height: "20px", marginRight: 4 }}
            />
            Start
          </Button>
          {open && (
            <List
              style={{
                position: "absolute",
                left: "0",
                top: "100%",
              }}
              onClick={() => setOpen(false)}
            >
              <ListItem>
                <a
                  href="https://github.com/arturbien/React95"
                  alt="React 95 Github"
                  target="_blank"
                >
                  <img
                    src={computercard}
                    alt="green computer card"
                    style={{ paddingTop: 10 }}
                  />
                  <span> React95</span>
                </a>
              </ListItem>
              
            </List>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default WindowsStartBar;

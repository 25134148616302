const aboutMe = (
  <p>
    <span className="title">
 CODEVEX                                                                                      
</span>
    <br></br>
    -- Web3 Development Services--
    <br></br>
    <br></br>
    Bring your DEFI idea to market fast! 
    We offer fullstack development services for all EVM compatable DAPPS.
    Capable of bringing DEFI primitives to any EVM blockchain. 
    From Yield Farms to Autocomps to Lending DAPPS. 
    <br></br>
    
    <br></br>
    <br></br>For consultation, contact us at {" "}
    <a
      href="contact@codevex.xyz"
      style={{ color: "teal" }}
      target="_blank"
    >
      codevexllc@outlook.com.
    </a>{" "}
  
  </p>
);

export default aboutMe;
